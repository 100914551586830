.pageContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginContainer {
  box-shadow: -1px 1px 4px 0 rgb(16, 20, 24, 0.5);
  padding: 2rem;
  border-radius: 20px;
  width: 380px;
}
.loginContainer h4 {
  color: var(--primary-color);
}

.loginContainer header {
  text-align: center;
}

.loginContainer main {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1.8rem 0;
}

.loginContainer footer {
  text-align: center;
}

.loginContainer footer span {
  cursor: pointer;
  color: var(--blue);
  text-decoration: underline;
}

.loginContainer footer span:hover {
  color: rgb(44, 44, 153);
}

.forgotPasswordLink {
  color: var(--blue);
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  cursor: pointer;
}
