.mainTypingContainer {
  width: 100%;
  padding: 4rem 1rem;
  position: relative;
  margin-top: 2rem;
}
.mainTypingContainer .blurText {
  display: none;
}
.mainTypingContainer_blur .textContainer {
  filter: blur(8.5px);
}

.mainTypingContainer_blur .blurText {
  display: block;
  position: relative;
  top: 85px;
  font-weight: 300;
  letter-spacing: 4px;
}

.textContainer {
  pointer-events: none;
  font-family: "Roboto Mono", monospace;
  display: flex;
  justify-content: center;
  gap: 11px;
  flex-wrap: wrap;
  position: relative;
}

.mainTypingContainer section {
  display: flex;
  justify-content: space-between;
  padding: 0 4rem;
}

.mainTypingContainer section span {
  color: var(--secondary-color);
  font-weight: 600;
}

.inputBox {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  top: 0;
  left: 0;
}
