.showResultContainer {
  width: 100%;
  position: relative;
  display: flex;
  padding: 0 2rem;
  /* border: 2px solid white; */
}

.leftSide {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leftSide div {
  display: flex;
  align-items: end;
  margin-top: 1.8rem;
}

.leftSide p {
  font-weight: 500;
}

.leftSide h1 {
  line-height: 85%;
}

.rightSide {
  flex: 1;
}

.rightSide main {
  padding: 0 2rem;
  height: 40vh;
}

.rightSide section {
  cursor: pointer;
  display: flex;
  justify-content: center;

  color: var(--primary-color);
  padding: 2.5rem 2rem;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.nextButton {
  display: flex;
  justify-content: center;
}

.nextButton button {
  font-size: var(--body1);
  padding: 0.5em 1.5em;
  border: none;
  border: 2px solid var(--secondary-color);
  background-color: transparent;
  border-radius: 8px;
  color: var(--primary-color);
}

.nextButton button:hover {
  border: 2px solid var(--secondary-color-dark);
  background-color: var(--background-color);
}
