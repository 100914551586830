.onlineResultContainer {
}

.onlineResultContainer > main {
  display: flex;
  padding: 0 8rem;
  gap: 1rem;
}

.normalResult {
  width: 70%;
  padding-top: 1rem;
}

.rightSide {
  flex: 1;
}

.rightSide p {
  text-align: center;
  padding: 1rem 0;
  /* box-shadow: inset 0 0 70px 0px rgba(255, 255, 255, 0.1); */
  border-radius: 8px;
}

.rankContainer {
  /* border: 1px solid white; */
  border-radius: 8px;
  display: flex;
  cursor: pointer;

  margin-bottom: 0.5rem;
  align-items: center;
}

.rankContainer:hover {
  box-shadow: inset 0 0 1px 1px rgb(255, 255, 255, 0.63);
}
.rankContainer .rank {
  text-align: center;
  width: 15%;
  padding: 1rem 0;
  font-weight: 700;
  border-radius: 8px 0 0 8px;
  box-shadow: inset 0 0 70px 0px rgba(255, 255, 255, 0.1);
}

.rankContainer .name {
  flex: 1;
  box-shadow: inset 0 0 70px 0px rgba(255, 255, 255, 0.05);
  padding: 1rem 0;
  text-align: center;
}

.rankContainer .wpm {
  box-shadow: inset 0 0 70px 0px rgba(255, 255, 255, 0.1);
  /* border-radius: 8px; */
  border-radius: 0 8px 8px 0;
  text-align: center;
  width: 15%;
  padding: 1rem 0;
}
