/* .correct {
  color: var(--primary-color);
} */

.typeLetterContainer {
  position: relative;
}

.typeLetterContainer > div {
  transition: all 0.15s ease;
}

.typeLetterContainer span,
.typeLetterContainer_incorrect span {
  font-family: "Roboto Mono", monospace;
  color: var(--secondary-color);
  letter-spacing: 2px;
}

/* .typeLetterContainer_incorrect::after {
  content: "";
  position: absolute;
  background-color: red;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

.iocorrect {
  color: red;
} */
