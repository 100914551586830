* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  font-size: 16px;
  /* font-family: "Abril Fatface", serif; */
  /* font-family: 'Roboto Mono', monospace; */
  height: 100vh;
  /* background: rgb(2, 23, 44); */
  background: linear-gradient(
    135deg,
    hsl(205, 100%, 64%) 0%,
    hsl(205, 100%, 36%) 100%
  );
  /* background: linear-gradient(
    321deg,
    rgba(16, 20, 24, 1) 16%,
    rgb(12, 42, 72) 100%
  ); */
  color: var(--primary-color);
  --body1: 1rem;
  --body2: 0.875rem;
}

.light {
  --background-color: #ffffff;
  --primary-color: #101418;
  --secondary-color: #444444;
}

.dark {
  --background-color: rgb(16, 20, 24);
  --primary-color: rgb(255, 255, 255);
  --secondary-color: rgb(69, 69, 69);
  --secondary-color-dark: rgb(56, 56, 56);
  --error-color: #ad3733;
  /* --error-color: rgb(244, 64, 52); */
}

h6,
.h6 {
  font-size: 1.25rem;
  line-height: 160%;
}

h5,
.h5 {
  font-size: 1.7rem;
  line-height: 133.4%;
}
h4,
.h4 {
  font-size: 2.125rem;
  line-height: 123.5%;
}
h3 {
  font-size: 3rem;
  line-height: 116.7%;
}
h2 {
  font-size: 3.75rem;
  line-height: 120%;
}
h1 {
  font-size: 6rem;
  line-height: 116.7%;
}

/* .typeLetterContainer_incorrect::after {
  content: "";
  position: absolute;
  background-color: red;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

.iocorrect {
  color: red;
}

.correct {
  color: var(--primary-color);
} */

.wordError::after {
  content: "";
  position: absolute;
  background-color: var(--error-color);
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

.cursorActive {
  position: absolute;
  background-color: var(--primary-color);
  height: 100%;
  width: 2px;
  border-radius: 2px;
  animation: blink 1000ms ease 0s infinite none;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
