/* .typedTextContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
} */

.err {
  color: var(--error-color);
}

.wordErr {
  position: relative;
  /* border-bottom: 1px dotted var(--error-color); */
}
.wordErr::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  border-top: 2px dotted var(--error-color);
}
