.offlinePopupContainer {
  position: absolute;
  top: -40px;
  left: 50%;
  font-size: 14px;
  transform: translateX(-50%);
  padding: 0.5em 2em;
  border-radius: 8px;
  background-color: hsl(205, 100%, 29%);
  box-shadow: 0 0 8px 0 rgb(255, 255, 255, 0.1);
}
