.serverLoaderContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
}

.serverLoaderContainer p {
  color: var(--secondary-color);
  font-size: 12px;
}

.loadingwave {
  /* width: 300px;
  height: 100px; */
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.loadingbar {
  width: 4px;
  height: 10px;
  margin: 0 1.5px;
  background-color: var(--secondary-color);
  border-radius: 5px;
  animation: loading-wave-animation 1s ease-in-out infinite;
}

.loadingbar:nth-child(2) {
  animation-delay: 0.1s;
}

.loadingbar:nth-child(3) {
  animation-delay: 0.2s;
}

.loadingbar:nth-child(4) {
  animation-delay: 0.3s;
}

@keyframes loading-wave-animation {
  0% {
    height: 6px;
  }

  50% {
    height: 20px;
  }

  100% {
    height: 6px;
  }
}
