.inputContainer {
  /* border: 1px solid black; */
}

.inputContainer label {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: var(--body2);
  gap: 0.15em;
  font-weight: 500;
}

.inputContainer p {
  /* position: relative;
  left: 1em; */
}

.inputContainer label input {
  font-size: var(--body1);
  background-color: var(--primary-color);
  border: none;
  outline: none;
  border: 1px solid var(--primary-color);
  padding: 0.6em 0.8em;
  width: 100%;
  border-radius: 1.5em;
  box-shadow: rgba(174, 169, 169, 0.16) 0px 3px 7px;
}

.inputContainer > label > .error {
  color: var(--primary-color);
  text-align: center;
  font-weight: 300;
  font-size: 0.7rem;
}
